<script>
	import Layout from '../../layouts/main'
	import PageHeader from '@/components/page-header'
	import appConfig from '@/app.config'
	import AWS  from 'aws-sdk'

	export default {
		middleware: 'authentication',
		page: {
			title: 'Onboarding Jounery',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
		},
		computed: {
			baseRootUrl: function() {
				return process.env.VUE_APP_FILE_URL
			},
		},
		mounted: function() {
			this.onboardEmployeeId = this.$route.params.onboardingId
			this.getOnboardingJourney()
		},
		data: function() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Onboarding List",
						active: true,
					},
				],
				//baseRootUrl: process.env.VUE_APP_FILE_URL,
				moment: this.$moment,
				onboardEmployeeId: null,
				onboardEmployeeDocumentList: [],
				onboardEmployeeRuleList: [],
				employeeDetails: '',
				timeline: [],
				fileUrl: '',
			}
		},
		methods: {
			getOnboardingJourney: function() {
				this.axios.post('onboard-employee/journey-list', {
					onboardEmployeeId: this.onboardEmployeeId,
				}).then((result) => {
					this.employeeDetails = result.data.data.onboardEmployeeDetail
					this.onboardEmployeeDocumentList = result.data.data.onboardEmployeeDocumentList
					this.onboardEmployeeRuleList = result.data.data.onboardEmployeeRuleList
					this.onboardEmployeeDocumentList.forEach((doc) => {
						this.timeline.push({
							id: doc._id,
							name: doc.name,
							filePath: doc.filePath,
							dateCreated: doc.createdAt,
							dateUpdated: doc.updatedAt,
							status: doc.status,
							day: '',
							materials: {},
							questions: [],
							trigger: '',
							triggerDate: '',
							triggerTime: '',
						})
					})
					this.onboardEmployeeRuleList.forEach((rule) => {
						this.timeline.push({
							id: rule._id,
							name: rule.action,
							filePath: '',
							dateCreated: rule.createdAt,
							dateUpdated: rule.updatedAt,
							status: rule.status,
							day: rule.day,
							materials: rule.materials,
							questions: rule.questions,
							trigger: rule.trigger,
							triggerDate: rule.triggerDate,
							triggerTime: rule.triggerTime,
						});
					})
					this.timeline = this.timeline.sort((a, b) => {
						const date1 = new Date(a.dateUpdated)
						const date2 = new Date(b.dateUpdated)

						// var results = date1 > date2 ? a : (date1 < date2 ? b : 0)
						// var results = date1 > date2 ? a : b
						// console.log(results)
						return date2.getTime() - date1.getTime()
					})
				}).catch((error) => {
					console.log(error)
				})
			},
			download(fileName) {
				console.log(fileName)
				let name = fileName.substring(fileName.lastIndexOf('/') + 1)
				const s3Object = new AWS.S3({
					region: this.$region,
					credentials: {
						accessKeyId: this.$accessKeyId,
						secretAccessKey: this.$secretAccessKey,
					},
				})
				let url = s3Object.getSignedUrl('getObject', {
					Bucket: this.$bucket,
					Key: name,
					//Key: '0s4xNjupkOXcPmQKBdVJRlvJKafPBf8YLhtIBt0A.pdf'
				})
				this.forceFileDownload(url)
			},
			getFileUrl: function(fileName) {
				let name = fileName.substring(fileName.lastIndexOf('/') + 1)
				const s3Object = new AWS.S3({
					region: this.$region,
					credentials: {
						accessKeyId: this.$accessKeyId,
						secretAccessKey: this.$secretAccessKey,
					},
				})
				let url = s3Object.getSignedUrl('getObject', {
					Bucket: this.$bucket,
					Key: name,
					//Key: '0s4xNjupkOXcPmQKBdVJRlvJKafPBf8YLhtIBt0A.pdf'
				})
				return url
			},
			forceFileDownload: function(url) {
				// const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', '')
				document.body.appendChild(link)
				link.click()
			},
			downloadFile: function(url) {
				console.log(url)
				let downloadUrl = this.baseRootUrl + url
				this.axios({
					method: 'get',
					downloadUrl,
					responseType: 'arraybuffer',
				}).then((result) => {
					this.forceFileDownload(result)
				}).catch((error) => {
					console.log(error)
				})
			},
			openPopup: function(fileName) {
				this.$refs.viewFiles.show()
				this.fileUrl = this.getFileUrl(fileName)
			},
		},
	}
</script>
<style>
	.page-content{
		min-height: 750px;
	}
	.card-body {
		padding: 1.25rem 1.25rem !important;
	}
	.verti-timeline {
		margin: 0;
		padding-left: 200px;
	}
	.verti-timeline .event-list .event-date {
		left: -202px;
	}
	.questions {
		list-style-type: none;
	}
	.card {
		margin-bottom: 1rem !important;
	}
	.btn, .btn:active, .btn:hover {
		box-shadow: none;
		outline: none;
	}
	.btn {
		margin-right: 8px;
	}
	.btn:last-child {
		margin: 0;
	}
	.modal-header {
		padding: 0;
		border: 0 none;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="col-md-6">
							<table class="table table-bodered">
								<tbody>
									<tr>
										<td>Name</td>
										<td>{{ employeeDetails.name }}</td>
									</tr>
									<tr>
										<td>Designation</td>
										<td>{{ employeeDetails.designation }}</td>
									</tr>
									<tr>
										<td>Phone Number</td>
										<td>{{ employeeDetails.phoneNumber }}</td>
									</tr>
									<tr>
										<td>Date of Joining</td>
										<td>{{ moment(employeeDetails.joiningDate).format('DD-MM-YYYY') }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div>
							<ul class="verti-timeline list-unstyled">
								<li v-for="tData in timeline" :key="tData.id" class="event-list">
									<div class="event-date text-primar">{{ moment(tData.dateUpdated).format('DD MMMM YYYY hh:mm A') }}</div>
									<h5>{{ employeeDetails.name + ' uploaded ' + tData.name }}</h5>
									<div class="text-muted">
										<ul class="questions" v-if="tData.name == 'Collect Information'">
											<li v-for="question in tData.questions" :key="question._id">
												<div class="question">Q: {{ question.question }}</div>
												<div class="answer">A: {{ question.answer }}</div>
											</li>
										</ul>
										<div v-else-if="tData.name == 'Send Material'">
											<button class="btn btn-primary btn-outline" @click="openPopup(tData.materials.materialLink)">View</button>
											<a href="#" class="btn btn-primary btn-outline" @click.prevent="download(tData.materials.materialLink)">Download</a>
										</div>
										<div v-else>
											<button class="btn btn-primary btn-outline" @click="openPopup(tData.filePath)">View</button>
											<a href="#" class="btn btn-primary btn-outline" @click.prevent="download(tData.filePath)">Download</a>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal centered ref="viewFiles" hide-footer size="lg" hide-title title="">
			<div class="d-flex justyfy-content-center">
				<embed :src="fileUrl" width="100%" />
			</div>
		</b-modal>
	</Layout>
</template>